html {
  background-color: #18191a;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
#root {
  width: 100%;
  height: 100%;
}
/**************** CUSTOM DROPDOWN ****************/
.customDropdown {
  background-color: #242526 !important;
  border: none !important;
}
.customDropdown:hover {
  outline: none !important;
  border: none !important;
  background-color: #242526 !important;
}
.customDropdown:focus {
  outline: none !important;
  border: none !important;
  background-color: #242526 !important;
}
.customDropdown:active {
  outline: none !important;
  border: none !important;
  background-color: #242526 !important;
}
.customDropdown.dropdown-toggle::after {
  display: none !important;
  border: none !important;
}
.dropdown-menu.show {
  background-color: #242526;
  border: 1px solid #393a3b;
}
.dropdown-menu.show .dropdown-item {
  background-color: #242526;
  color: #e4e6eb;
}
.dropdown-menu.show .dropdown-item:hover {
  background-color: rgba(100, 100, 100, 0.4);
}
.dropdown-menu.show .dropdown-item-text {
  color: #e4e6eb;
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid #393a3b;
  padding: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 180px;
}
/****************************************************/
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #3a3b3c;
  background-color: #18191a;
  /************************** Home PAGE **********************/
  /************************** SOCIAL NETWORK PAGE **********************/
  /************************** NEWSFEED PAGE **********************/
  /************************** POST PAGE **********************/
  /************************** USER PAGE **********************/
  /************************** USER PAGE **********************/
}
body.modal-open {
  padding-right: 0 !important;
}
body .navItem {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
body .navItem .navIcon {
  margin: 0;
  padding: 0;
  font-size: 15px;
  color: #b0b3b8;
}
body .navItem .navText {
  margin: 3px 0 0 6px;
  padding: 0;
  font-size: 15px;
  color: #b0b3b8;
  font-weight: bold;
}
body .submitButtonContainer {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}
body .submitButtonContainer {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}
body .submitButtonContainer .buttonGroup {
  display: flex;
  align-items: center;
  list-style: none;
}
body .submitButtonContainer .buttonGroup li {
  margin: 0 10px 0 0;
}
body .submitButtonContainer .buttonGroup li:last-child {
  margin: 0;
}
body .customModal {
  color: #e0e2e7;
  border: 1px solid #393a3b;
}
body .customModal .modal-content {
  background-color: #242526;
}
body .customModal .modal-content .modal-header {
  background-color: #242526;
  color: #e4e6eb;
  border-bottom: 1px solid #393a3b;
}
body .customModal .modal-content .modal-header .btn-close {
  outline: none !important;
  outline: 0 !important;
}
body .customModal .modal-content .modal-header .btn-close:focus {
  outline: none !important;
  outline: 0 !important;
}
body .customModal .modal-content .modal-body {
  background-color: #242526;
  color: #e0e2e7;
}
body .customModal .modal-content .modal-body .formMessage {
  color: #e0e2e7;
  margin-bottom: 10px;
}
body .customModal .modal-content .modal-body .form-control {
  background-color: #18191a;
  color: #e0e2e7;
  border: 1px solid #393a3b;
}
body .customModal .modal-content .modal-body .form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #61676f;
  opacity: 1;
  /* Firefox */
}
body .customModal .modal-content .modal-body .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #61676f;
}
body .customModal .modal-content .modal-body .form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #61676f;
}
body .customModal .modal-content .modal-body .formGroup {
  margin-top: 10px;
}
body .customModal .modal-content .modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #e0e2e7;
  border-top: 1px solid #393a3b;
}
body .customModal .modal-content .logInModalMessage {
  font-size: 15px;
  width: 100%;
  text-align: center;
}
body .customModal .modal-content .logInModalMessage .link {
  color: #256af6;
  font-weight: bold;
  cursor: pointer;
}
body .banner {
  margin: 0 0 20px 0;
}
body .banner.notModal {
  margin: 20px 0;
}
body .home {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("./img/bg-3840.jpg");
  background-size: cover;
  background-attachment: fixed;
  overflow: scroll;
  margin: 0;
  padding: 0 0 30px 0;
}
body .home .sectionTitle {
  font-weight: bold;
  color: white;
  margin: 10px 0 0 0 ;
  text-align: center;
}
@media only screen and (min-width: 711px) {
  body .home .sectionTitle {
    font-size: 50px;
  }
}
@media only screen and (min-width: 501px) and (max-width: 710px) {
  body .home .sectionTitle {
    font-size: 40px;
  }
}
@media only screen and (max-width: 500px) {
  body .home .sectionTitle {
    font-size: 32px;
  }
}
@media only screen and (max-width: 380px) {
  body .home .sectionTitle {
    font-size: 26px;
  }
}
body .home .card {
  margin: 20px 0;
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
}
@media only screen and (min-width: 1001px) {
  body .home .card {
    width: 840px;
  }
}
@media only screen and (min-width: 711px) and (max-width: 1000px) {
  body .home .card {
    width: 80%;
  }
}
@media only screen and (max-width: 710px) {
  body .home .card {
    width: 90%;
  }
}
body .home .card.project {
  padding: 30px 30px 50px 30px;
}
body .home .card .cardBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333333;
}
body .home .card .cardBody .customCarousel {
  width: 100%;
  margin: 30px 0;
  display: flex;
  align-items: center;
  max-width: 100%;
}
body .home .card .cardBody .customCarousel img {
  width: 100%;
}
body .home .card .cardBody .customCarousel .carouselNavItemHolder {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 0;
}
body .home .card .cardBody .customCarousel .carouselNavItemHolder .carouselNavItem {
  font-size: 2em;
  cursor: pointer;
  color: darkgray;
  padding: 0;
  z-index: 3;
}
body .home .card .cardBody .customCarousel .carouselNavItemHolder .carouselNavItem.left {
  margin-right: -50px;
}
body .home .card .cardBody .customCarousel .carouselNavItemHolder .carouselNavItem.right {
  margin-left: -32px;
}
body .home .card .cardBody .avatar {
  border-radius: 50%;
  margin-bottom: 20px;
  border: 2px solid #e7ecf7;
}
@media only screen and (min-width: 711px) {
  body .home .card .cardBody .avatar {
    width: 200px;
    height: 200px;
  }
}
@media only screen and (min-width: 501px) and (max-width: 710px) {
  body .home .card .cardBody .avatar {
    width: 160px;
    height: 160px;
  }
}
@media only screen and (max-width: 500px) {
  body .home .card .cardBody .avatar {
    width: 128px;
    height: 128px;
  }
}
body .home .card .cardBody .hello {
  color: #111111;
  font-weight: 500;
  margin-bottom: 15px;
  text-align: center;
}
@media only screen and (min-width: 711px) {
  body .home .card .cardBody .hello {
    font-size: 30px;
  }
}
@media only screen and (min-width: 501px) and (max-width: 710px) {
  body .home .card .cardBody .hello {
    font-size: 24px;
  }
}
@media only screen and (max-width: 500px) {
  body .home .card .cardBody .hello {
    font-size: 20px;
  }
}
body .home .card .cardBody .intro {
  color: #333333;
  font-size: 20px;
  text-align: center;
}
@media only screen and (min-width: 501px) {
  body .home .card .cardBody .intro {
    font-size: 20px;
  }
}
@media only screen and (max-width: 500px) {
  body .home .card .cardBody .intro {
    font-size: 16px;
  }
}
body .home .card .cardBody .techStacks {
  width: 100%;
  list-style: none;
  display: flex;
  margin: 20px 0 0 0;
  padding: 0;
}
@media only screen and (min-width: 711px) {
  body .home .card .cardBody .techStacks {
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
  }
}
@media only screen and (max-width: 710px) {
  body .home .card .cardBody .techStacks {
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
  }
}
body .home .card .cardBody .techStacks li {
  padding: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media only screen and (min-width: 711px) {
  body .home .card .cardBody .techStacks li {
    margin: 0 20px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 180px;
  }
}
@media only screen and (max-width: 710px) {
  body .home .card .cardBody .techStacks li {
    margin: 0 0 20px 0;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
  }
}
body .home .card .cardBody .techStacks li .stackTitle {
  font-weight: bold;
}
@media only screen and (min-width: 501px) {
  body .home .card .cardBody .techStacks li .stackTitle {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 500px) {
  body .home .card .cardBody .techStacks li .stackTitle {
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 501px) {
  body .home .card .cardBody .techStacks li .stackList {
    font-size: 18px;
  }
}
@media only screen and (max-width: 500px) {
  body .home .card .cardBody .techStacks li .stackList {
    font-size: 16px;
  }
}
body .socialnetwork {
  width: 100%;
  height: 100%;
  background-color: #18191a;
}
body .socialnetwork .spinnerContainer {
  margin-top: 100px;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
body .socialnetwork .errorContainer {
  margin: 30px 0;
  padding: 0;
  width: 100%;
  text-align: center;
  color: #e0e2e7;
  font-weight: bold;
}
body .socialnetwork .mainNav {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px solid #393a3b;
  background-color: #242526;
  position: fixed;
  z-index: 10;
}
body .socialnetwork .mainNav .leftNav {
  margin: 0 -15px 0 0;
  padding: 0;
  display: flex;
  align-content: center;
}
body .socialnetwork .mainNav .leftNav .mainNavDropdown {
  margin: 0 0 0 20px ;
  padding: 0;
}
body .socialnetwork .mainNav .leftNav .icon {
  font-size: 22px;
  color: #b0b3b8;
}
body .socialnetwork .mainNav .leftNav .nameIcon {
  font-size: 22px;
  color: #b0b3b8;
  margin: 0 10px 0 8px;
}
body .socialnetwork .mainLayout {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  top: 80px;
}
body .socialnetwork .mainLayout .container {
  margin: 0;
  padding: 0;
}
@media only screen and (min-width: 701px) {
  body .socialnetwork .mainLayout .container {
    width: 600px;
  }
}
@media only screen and (max-width: 700px) {
  body .socialnetwork .mainLayout .container {
    width: 90%;
  }
}
body .newsfeedPage {
  width: 100%;
  margin: 0;
  padding: 0;
}
body .newsfeedPage .sortElement {
  margin: 10px 0 30px 0;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #e0e2e7;
  list-style: none;
}
body .newsfeedPage .sortElement li {
  margin-right: 30px;
}
body .newsfeedPage .sortElement li:last-child {
  margin-right: 0;
}
body .newsfeedPage .sortElement .active {
  color: #256af6;
  font-weight: bold;
}
body .newsfeedPage .sortElement .inactive {
  color: #e0e2e7;
  cursor: pointer;
}
body .newsfeedPage .posts {
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}
body .newsfeedPage .posts li {
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid #393a3b;
  background-color: #242526;
  border-radius: 8px;
  margin: 0 0 20px 0;
}
body .newsfeedPage .posts li .postHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}
body .newsfeedPage .posts li .postHeader .left {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}
body .newsfeedPage .posts li .postHeader .left .icon {
  color: #b0b3b8;
  font-size: 22px;
  cursor: pointer;
}
body .newsfeedPage .posts li .postHeader .left .smallIcon {
  color: #b0b3b8;
  font-size: 15px;
  cursor: pointer;
}
body .newsfeedPage .posts li .postHeader .left .createBy {
  font-weight: bold;
  font-size: 12px;
  color: #256af6;
  margin: 4px 0 0 10px;
  padding: 0;
  cursor: pointer;
}
body .newsfeedPage .posts li .postHeader .left .createAt {
  font-size: 12px;
  margin: 4px 0 0 20px;
  padding: 0;
  color: #e0e2e7;
}
body .newsfeedPage .posts li .postHeader .left .comment {
  font-size: 12px;
  margin: 4px 0 0 20px;
  padding: 0;
  color: #e0e2e7;
}
body .newsfeedPage .posts li .postHeader .right {
  color: #b0b3b8;
  font-size: 14px;
}
body .newsfeedPage .posts li .title {
  font-size: 22px;
  font-weight: 800;
  color: #e4e6eb;
  cursor: pointer;
}
body .newsfeedPage .posts li .content {
  font-size: 15px;
  color: #e0e2e7;
  cursor: pointer;
  padding: 10px 20px 0 0;
}
body .postPage {
  width: 100%;
  margin: 0;
  padding: 0;
  color: #e0e2e7;
}
body .postPage .sortElement {
  margin: 20px 0 10px 0;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #e0e2e7;
  list-style: none;
}
body .postPage .sortElement li {
  margin: 0;
  padding-right: 30px;
}
body .postPage .sortElement li:last-child {
  padding-right: 0;
}
body .postPage .sortElement .active {
  color: #256af6;
  font-weight: bold;
}
body .postPage .sortElement .inactive {
  color: #e0e2e7;
  cursor: pointer;
}
body .postPage .form-control {
  background-color: #18191a;
  color: #e0e2e7;
  border: 1px solid #393a3b;
}
body .postPage .form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #61676f;
  opacity: 1;
  /* Firefox */
}
body .postPage .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #61676f;
}
body .postPage .form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #61676f;
}
body .postPage .formGroup {
  margin-top: 20px;
}
body .postPage .post {
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid #393a3b;
  background-color: #242526;
  border-radius: 8px;
  margin: 0 0 20px 0;
}
body .postPage .post .postHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}
body .postPage .post .postHeader .left {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}
body .postPage .post .postHeader .left .icon {
  color: #b0b3b8;
  font-size: 22px;
  cursor: pointer;
}
body .postPage .post .postHeader .left .createBy {
  font-weight: bold;
  font-size: 12px;
  color: #256af6;
  margin: 4px 0 0 10px;
  padding: 0;
  cursor: pointer;
}
body .postPage .post .postHeader .left .createAt {
  font-size: 12px;
  margin: 4px 0 0 20px;
  padding: 0;
  color: #e0e2e7;
}
body .postPage .post .postHeader .left .comment {
  font-size: 12px;
  margin: 4px 0 0 20px;
  padding: 0;
  color: #e0e2e7;
}
body .postPage .post .postHeader .right {
  color: #b0b3b8;
  font-size: 14px;
}
body .postPage .post .title {
  font-size: 22px;
  font-weight: 800;
  color: #e4e6eb;
}
body .postPage .post .content {
  font-size: 15px;
  color: #e0e2e7;
  padding: 10px 20px 0 0;
}
body .postPage .post .comments {
  list-style: none;
  margin: 0;
  padding: 0;
}
body .postPage .post .comments .comment {
  margin: 0 0 10px 0;
  padding: 0;
}
body .postPage .post .comments .comment .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
body .postPage .post .comments .comment .header .left {
  display: flex;
  align-items: center;
}
body .postPage .post .comments .comment .header .left .icon {
  font-size: 22px;
  color: #b0b3b8;
}
body .postPage .post .comments .comment .header .left .author {
  font-weight: bold;
  font-size: 12px;
  color: #256af6;
  margin: 4px 0 0 10px;
  padding: 0;
}
body .postPage .post .comments .comment .header .left .createAt {
  font-size: 12px;
  margin: 4px 0 0 20px;
  padding: 0;
  color: #e0e2e7;
}
body .postPage .post .comments .comment .header .right .dropdownToggleText {
  font-size: 12px;
  color: #b0b3b8;
}
body .postPage .post .comments .comment .content {
  margin-left: 34px;
  padding: 5px 20px 0 0;
}
body .userPage {
  width: 100%;
  margin: 0;
  padding: 0;
  color: #e0e2e7;
}
body .userPage .profile {
  margin: 0 0 20px 0;
  padding: 0;
  display: flex;
  align-content: center;
}
body .userPage .profile .icon {
  font-size: 42px;
  color: #b0b3b8;
}
body .userPage .profile .name {
  font-size: 30px;
  color: #e0e2e7;
  margin-left: 20px;
  font-weight: 800;
}
body .userPage .content {
  margin: 0;
  padding: 0;
}
body .userPage .content .posts {
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}
body .userPage .content .posts li {
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid #393a3b;
  background-color: #242526;
  border-radius: 8px;
  margin: 0 0 20px 0;
}
body .userPage .content .posts li .postHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}
body .userPage .content .posts li .postHeader .left {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}
body .userPage .content .posts li .postHeader .left .icon {
  color: #b0b3b8;
  font-size: 22px;
  cursor: pointer;
}
body .userPage .content .posts li .postHeader .left .smallIcon {
  color: #b0b3b8;
  font-size: 15px;
  cursor: pointer;
}
body .userPage .content .posts li .postHeader .left .createAt {
  font-size: 12px;
  margin: 4px 0 0 20px;
  padding: 0;
  color: #e0e2e7;
}
body .userPage .content .posts li .postHeader .left .comment {
  font-size: 12px;
  margin: 4px 0 0 0;
  padding: 0;
  color: #e0e2e7;
}
body .userPage .content .posts li .postHeader .right {
  color: #b0b3b8;
  font-size: 14px;
}
body .userPage .content .posts li .title {
  font-size: 22px;
  font-weight: 800;
  color: #e4e6eb;
  cursor: pointer;
}
body .userPage .content .posts li .content {
  font-size: 15px;
  color: #e0e2e7;
  cursor: pointer;
  padding: 10px 20px 0 0;
}
body .userPage .content .posts li .commentButtonContainer {
  width: 100%;
  margin: 20px 0 0 0;
  display: flex;
  justify-content: center;
}
body .resetPasswordPage {
  width: 100%;
  margin: 0;
  padding: 0;
  color: #e0e2e7;
  display: flex;
  justify-content: center;
}
body .resetPasswordPage .formContainer {
  background-color: #242526;
  margin: 0;
  padding: 20px 65px;
  border-radius: 10px;
  width: 100%;
}
body .resetPasswordPage .formContainer .resetPasswordTitle {
  color: #e4e6eb;
  margin-bottom: 20px;
}
body .resetPasswordPage .formContainer .form-control {
  background-color: #18191a;
  color: #e0e2e7;
  border: 1px solid #393a3b;
  width: 100%;
}
body .resetPasswordPage .formContainer .form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #61676f;
  opacity: 1;
  /* Firefox */
}
body .resetPasswordPage .formContainer .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #61676f;
}
body .resetPasswordPage .formContainer .form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #61676f;
}
body .resetPasswordPage .formContainer .formGroup {
  margin-top: 20px;
}
body .settingPage {
  width: 100%;
  margin: 0;
  padding: 0;
  color: #e0e2e7;
  display: flex;
  justify-content: center;
}
body .settingPage .formContainer {
  background-color: #242526;
  margin: 0;
  padding: 20px 65px;
  border-radius: 10px;
  width: 100%;
}
body .settingPage .formContainer .profile {
  margin: 0 0 20px 0;
  padding: 0;
  display: flex;
  align-content: center;
}
body .settingPage .formContainer .profile .icon {
  font-size: 42px;
  color: #b0b3b8;
}
body .settingPage .formContainer .profile .name {
  font-size: 30px;
  color: #e0e2e7;
  margin-left: 20px;
  font-weight: 800;
}
body .settingPage .formContainer .resetPasswordTitle {
  color: #e4e6eb;
  margin-bottom: 20px;
}
body .settingPage .formContainer .form-control {
  background-color: #18191a;
  color: #e0e2e7;
  border: 1px solid #393a3b;
  width: 100%;
}
body .settingPage .formContainer .form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #61676f;
  opacity: 1;
  /* Firefox */
}
body .settingPage .formContainer .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #61676f;
}
body .settingPage .formContainer .form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #61676f;
}
body .settingPage .formContainer .formGroup {
  margin-top: 20px;
}
